<template>
  <div>
  <CCard>
   
  <CCardBody>
    <div class="clearfix">
      <button type="button" class="btn btn-primary float-xl-right" @click="navToNewSymptoms">New Item</button>
    </div>

    <CDataTable :items="items"  
    :fields="fields"
     striped
      clickable-rows
      column-filter
       table-filter
        items-per-page-select
      :items-per-page="20"
       sorter
       ref="vuetable"
      pagination
      :responsive="false"
      >
        
         <template #popular-filter>
          <select
            class="form-control form-control-sm"
            @input="$refs.vuetable.columnFilterEvent('popular', $event.target.value, 'input')"
          >
            <option value selected="selected">Any</option>
            <option value="1"> Yes </option>
            <option value="2">No</option>
          </select>
        </template>
    
       <template #popular="{item}">
        <td class='font-weight-bold'
          v-if="item.popular == true">
            Yes  </td>
          <td class='font-weight-bold'  v-else>No </td>
       
        
      </template>  
      <template #index="data">
       <td>
         {{data.index +1}}
         </td>
     </template>

      <template #createdTime="{item}">
        <td class='font-weight-bold'
          v-if="item.createdTime"
        >{{ item.createdTime |dateParse()| dateFormat("MMM D, YYYY hh:mm A") }}</td>
        <td v-else>--</td>
      </template>
     
       <!-- <template #userType="{item}">
        <td
          v-if="item.userType == 1">
            Doctor  </td>
          <td v-else>No </td>
       
        
      </template>   -->
       <template #show_details="{item}">
        <td class="py-2">
       <!-- <div class="btn-group dropup"> -->
        <CDropdown
                  togglerText="Actions"
                  color="info"
                  
                >
                  <CDropdownItem :to="{name: 'SWP Item', params: {swpItemId:item.swpItemId,categoryId:item.categoryId, mode: 'view'}}">View </CDropdownItem>
           
                <!-- <ConfirmationModal
                      :parent="$refs.modalArea"
                      :description="'Are you sure to Delete'"
                      @on:ok="deleteGroup(item.categoryId)"
                    ></ConfirmationModal> -->

                </CDropdown>
       <!-- </div> -->
        
        </td>
      </template>
      </CDataTable>
  </CCardBody>
  <div ref="modalArea"></div>
<CCardFooter align="right">
         
            <CButton class="mr-2" type="Cancel" size="sm" color="danger"  @click="cancel()">
              <CIcon name="cil-ban" /> Cancel
            </CButton>
          </CCardFooter>
 </CCard>
 </div>
</template>

<script>
const fields = [
  'index',
 
   { key: "title",_classes: 'font-weight-bold',  _style: "min-width:200px" },
    { key: "popular",_classes: 'font-weight-bold',  _style: "min-width:200px" },


  { key: "createdTime",label: "Created Date & Time",_classes: 'font-weight-bold', _style: "min-width:200px" },
   { key: "show_details",
    label: "",
    _style: "width:1%",
    sorter: false,
    filter: false,
  },
  
];
import ConfirmationModal from "../../containers/ConfirmationModal";
export default {
  name: "SWP Items",
components: {
     ConfirmationModal,
   },
  data() {
    return {
      items: [],
      fields,
      details: [],
      collapseDuration: 0,
    };
  },
  methods: {
    getSwp() {
     this.apiGetSecure(process.env.VUE_APP_API_HOST + "/emapp/web/secure/get/swp/items/"+ this.$route.params.categoryId)
        .then((response) => response.json())
        .then((data) => (this.items = data));
    },
    //  movetrash(faqId) {
    //  this.apiGetSecure(process.env.VUE_APP_API_HOST + "/web/secure/delete/faq/" + faqId, {
    //     method: "GET",
    //     // headers: this.defaultHeaders()
    //   });
    //  this.getSymptoms();
    //   this.$nextTick(() => {
    //     this.$refs.vuetable.refresh();
    //   });
    //   window.location.reload();
    // },
    // deleteGroup(categoryId) {
    //   console.log("delete called with", this.categoryId);
    //   this.apiGetSecure(
    //     process.env.VUE_APP_API_HOST +"/emapp/web/swp/delete/category/"+ categoryId)
    //     .then((response) => response.json())
    //     .then((data) => {
    //       this.getSwp();
    //          this.$nextTick(() => {
    //          this.$refs.vuetable.refresh();
    //        });
    //      window.location.reload();
    //     });
    // },
     cancel() {
    
             this.$router.push({ name: "SWPs" ,params: {subGroupId: this.$route.params.subGroupId}});
    },
    navToNewSymptoms(){
       this.$router.push({ name: "SWP Item", params: {categoryId: this.$route.params.categoryId, mode: "new" } });
    }
  },
  mounted() {
    this.getSwp();
  },
};
</script>